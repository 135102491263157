import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
@Component({
  components: {
  }
})
export default class NineCardGameView extends Vue {

  selectedDrawerItem = 1;

  @Watch('selectedDrawerItem')
  public skipSubPage(name: string) {
    switch (name) {
      case '1':
        this.$router.push('/tools/ninecard/home');
        break;
      case '2':
        this.$router.push('/cards/edit');
        break;
      case '3':
        this.$router.push('/card/edit');
        break;
      case '4':
        this.$router.push('/scheme/edit');
        break;

    }


  }



}