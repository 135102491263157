<template>
  <div class="nine-card-game-view">
    <v-card dark>
      <v-card-title>
        FFXIV 九宫幻卡 NPC 对局模拟器 <v-spacer></v-spacer>

        <v-btn-toggle v-model="selectedDrawerItem" mandatory>
          <v-btn value="1" small>
            <v-icon left>mdi-monitor-dashboard</v-icon>
            <span class="hidden-sm-and-down">Home</span>
          </v-btn>

          <v-btn value="2" small>
            <v-icon left>mdi-book-edit</v-icon>
            <span class="hidden-sm-and-down">NPC&卡组编辑器</span>
          </v-btn>

          <v-btn value="3" small>
            <v-icon left>mdi-file-edit</v-icon>
            <span class="hidden-sm-and-down">卡牌编辑器</span>
          </v-btn>

          <v-btn value="4" small>
            <v-icon left>mdi-sitemap</v-icon>
            <span class="hidden-sm-and-down">对局编辑器</span>
          </v-btn>
        </v-btn-toggle>
      </v-card-title>
    </v-card>
    <v-container fluid>
      <router-view />
    </v-container>
  </div>
</template>

<style scoped>
.nine-card-game-view {
  background-color: #333;
  height: 100%;
}
html {
  overflow-y: auto;
}
</style>


<script>
import NineCardView from "./nineCard";
export default NineCardView;
</script>
